<template>
<div style="width: 100%; height: 75vh">
    
 <div class="alert alert-secondary row" role="alert">
   <div class="col-md-2">
     <strong>Filtern nach<br />Ausbildungsberuf</strong>

     </div>

      <div class="col-md-10">
        <select v-model="filter_job" class="form-control">
      <option v-for="job in jobs">{{job}}</option>
    </select>
     </div>
</div>


  
    <GmapMap :options="{
   zoomControl: true,
   mapTypeControl: false,
   scaleControl: false,
   streetViewControl: false,
   rotateControl: false,
   fullscreenControl: true,
   disableDefaultUi: false
 }"
      :center="{lat:49.30048729570266, lng:9.971129725023022}"
      :zoom="10.5"
      :fullscreenControl="'false'"
      :map-type-ontrol="'false'"
      map-type-id="roadmap"
      style="width: 100%; height: 100%"
    >
      <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">

         <div :class="'info_window container ' + infoContent.slug">
                            <h4>{{infoContent.company}}</h4>
<hr />
            <div class="row">
              <div class="col-md-8">
            <p>{{infoContent.teaser}}</p>
            <br />
                                    <router-link :to="`/company/${infoContent.slug}`" class="mx-auto btn btn-success">Unternehmensdetails</router-link>

                </div>
                <div class="col-md-4">
                              <img width="100%" class="thumbnail logo" :src="'/companies/'+infoContent.slug+'/'+infoContent.logo" />
                              <br /><br />
              <p>{{infoContent.street}}<br />{{infoContent.zipCode}} {{infoContent.city}}</p>
              <p v-if="infoContent.website!=null && infoContent.website.length>0"><a :href="infoContent.website[0]" target="_blank">{{infoContent.website[0]}}</a></p>
                </div>
            </div>

            
        </div>
      </GmapInfoWindow>

     <GmapMarker
      :key="index"
      v-for="(m, index) in markers.filter(c => c.active==true)"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      @click="toggleInfoWindow(m,i)"
    />
    </GmapMap>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
import companies from '../json/companies.json'
export default {
  name: 'CompanyMap',
  components: {
   
  },
  data () {
    return {
      filter_job : "--- kein Filter ---",
      
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoContent: '',
      infoOptions: {
        //content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    }
  },
  computed: {
    google: gmapApi,
    markers() {
      if(this.filter_job=="--- kein Filter ---")
        return companies
      
      let _markers = [];
      companies.forEach(company => {
        if(company.jobtitles.includes(this.filter_job))
          _markers.push(company);
      });
      return _markers;
    },
    jobs() {
      let _jobs = [];
      _jobs.push("--- kein Filter ---");
      companies.forEach(company => {
        company.jobtitles.forEach(job => {
          if(!_jobs.includes(job))
            _jobs.push(job);
        });
      });
      return _jobs.sort();
    }
  },
  methods: {
    toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoContent = marker;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
              this.infoWinOpen = true;
              this.currentMidx = idx;

            }
          }
  }
}
</script>

<style>
p {
  line-height:1.5em;
}

</style>
