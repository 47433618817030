var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"75vh"}},[_c('div',{staticClass:"alert alert-secondary row",attrs:{"role":"alert"}},[_vm._m(0),_c('div',{staticClass:"col-md-10"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter_job),expression:"filter_job"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.filter_job=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.jobs),function(job){return _c('option',[_vm._v(_vm._s(job))])}),0)])]),_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"options":{
   zoomControl: true,
   mapTypeControl: false,
   scaleControl: false,
   streetViewControl: false,
   rotateControl: false,
   fullscreenControl: true,
   disableDefaultUi: false
 },"center":{lat:49.30048729570266, lng:9.971129725023022},"zoom":10.5,"fullscreenControl":'false',"map-type-ontrol":'false',"map-type-id":"roadmap"}},[_c('GmapInfoWindow',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen=false}}},[_c('div',{class:'info_window container ' + _vm.infoContent.slug},[_c('h4',[_vm._v(_vm._s(_vm.infoContent.company))]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('p',[_vm._v(_vm._s(_vm.infoContent.teaser))]),_c('br'),_c('router-link',{staticClass:"mx-auto btn btn-success",attrs:{"to":("/company/" + (_vm.infoContent.slug))}},[_vm._v("Unternehmensdetails")])],1),_c('div',{staticClass:"col-md-4"},[_c('img',{staticClass:"thumbnail logo",attrs:{"width":"100%","src":'/companies/'+_vm.infoContent.slug+'/'+_vm.infoContent.logo}}),_c('br'),_c('br'),_c('p',[_vm._v(_vm._s(_vm.infoContent.street)),_c('br'),_vm._v(_vm._s(_vm.infoContent.zipCode)+" "+_vm._s(_vm.infoContent.city))]),(_vm.infoContent.website!=null && _vm.infoContent.website.length>0)?_c('p',[_c('a',{attrs:{"href":_vm.infoContent.website[0],"target":"_blank"}},[_vm._v(_vm._s(_vm.infoContent.website[0]))])]):_vm._e()])])])]),_vm._l((_vm.markers.filter(function (c) { return c.active==true; })),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false},on:{"click":function($event){return _vm.toggleInfoWindow(m,_vm.i)}}})})],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-2"},[_c('strong',[_vm._v("Filtern nach"),_c('br'),_vm._v("Ausbildungsberuf")])])}]

export { render, staticRenderFns }